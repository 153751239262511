import React from "react";
import Link from "./Link";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { useTranslation } from "gatsby-plugin-react-i18next";
import DarkMode from "./DarkMode";
import LanguageSwitcher from "./LanguageSwitcher";


export interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  onToggle?: () => void;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "navbarLinks teenused",
    children: [
      {
        label: "navbarLinks kodulehe tegemine",
        href: "/veebiarendus/",
      },
      {
        label: "navbarLinks sisu loomine",
        href: "/kodulehe-sisuloome/",
      },
      {
        label: "navbarLinks kodulehe hooldus",
        href: "/kodulehe-hooldus/",
      },
    ],
  },
  {
    label: "navbarLinks turundus",
    href: "/kodulehe-turundus/",
  },
  {
    label: "navbarLinks meist",
    href: "/meist/",
  },
  {
    label: "navbarLinks kontakt",
    href: "/kontakt/",
  },
];

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const { t } = useTranslation("common");
  return (
    <Box zIndex={4}>
      <Flex
        bg={useColorModeValue("brand.600", "brand.700")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Link href='/'>
            <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              color='brand.100'
              fontSize='md'
              fontWeight='800'
              fontFamily='brandHeading'
              letterSpacing='wide'
              aria-label='OÜ Kodulehe Tellimine'
              textDecoration='none'
              _hover={{
                color: "brand.50",
              }}
            >
              {t("navbarBrand")}
            </Text>
          </Link>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >

          <LanguageSwitcher onToggle={onToggle} />

          <DarkMode display={{ base: "none", md: "inline-flex" }} />
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onToggle={onToggle} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("brand.100", "brand.200");
  const linkHoverColor = useColorModeValue("brand.800", "white");
  const popoverContentBgColor = useColorModeValue("brand.200", "brand.800");
  const { t } = useTranslation("common");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem: NavItem) => (
        <Box key={navItem.label}>
          <Popover
            trigger={"hover"}
            placement={"bottom-start"}
            key={navItem.label}
          >
            <PopoverTrigger>
              <Box>
                <Link
                  p={2}
                  href={navItem.href ?? "#"}
                  fontSize={"sm"}
                  fontWeight={500}
                  fontFamily='brandHeading'
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                    bg: useColorModeValue("brand.200", "brand.800"),
                    borderRadius: "md",
                  }}
                >
                  {t(navItem.label)}
                </Link>
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                w={"xs"}
              >
                <Stack w={"full"}>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  const { t } = useTranslation("common");
  return (
    <Link
      aria-label={t(label) || "Navbar Link"}
      href={href ?? "#"}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{
        bg: useColorModeValue("brand.300", "brand.700"),
      }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{
              color: useColorModeValue("brand.800", "brand.50"),
            }}
            fontWeight={500}
            fontFamily='brandHeading'
          >
            {t(label)}
          </Text>
          <Text fontSize={"sm"} fontFamily='brandHeading'>
            {subLabel}
          </Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon
            color={useColorModeValue("brand.800", "brand.50")}
            w={5}
            h={5}
            as={ChevronRightIcon}
          />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = ({ onToggle }: { onToggle: () => void }) => {
  const { t } = useTranslation("common");
  return (
    <Stack
      bg={useColorModeValue("brand.500", "brand.900")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem: NavItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onToggle={onToggle} />
      ))}
      <DarkMode />
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, onToggle }: NavItem & { onToggle: () => void }) => {
  const { isOpen, onToggle: onToggleLocal } = useDisclosure({ defaultIsOpen: true });
  const { t } = useTranslation("common");

  const handleClick = () => {
    onToggle();
  };
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        onClick={handleClick}
      >
        <Text
          fontWeight={600}
          fontFamily='brandHeading'
          color={useColorModeValue("brand.50", "brand.200")}
        >
          {t(label)}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("brand.50", "brand.200")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link
                aria-label={t(label) || "Navbar Link"}
                key={child.label}
                py={4}
                href={child.href ?? "#"}
                display="block"
                color={useColorModeValue("brand.200", "brand.300")}
                fontFamily='brandHeading'
                onClick={handleClick}
              >
                - {t(child.label)}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};