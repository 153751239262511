import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Icon,
  useColorModeValue,
  HStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { FaFacebookF } from "react-icons/fa";
import { SiOpenai } from "react-icons/si";
import Link from "./Link";
import { useTranslation } from "gatsby-plugin-react-i18next";


type SocialLink = {
  href: string;
  title: string;
  icon: React.ElementType;
};

type Link = {
  href: string;
  text: string;
};

type LinkSection = {
  header: string;
  links: Link[];
};

const socialLinks = [
  {
    href: 'https://www.facebook.com/people/O%C3%9C-Kodulehe-Tellimine/100063911329234/',
    title: "Kodulehe Tellimine OÜ - Facebook",
    icon: FaFacebookF
  },
  {
    href: 'https://gptstore.ai/creators/user-Hbj4fwHX7EGrt7K1SZLVm2W5',
    title: "GPT Store - Kodulehe Tellimine OÜ",
    icon: SiOpenai
  }
];

const FooterLinkSection = ({ header, links }: LinkSection) => (
  <Stack align="flex-start" spacing={1}>
    <Text fontWeight="600" fontSize="md" mb={2}>
      {header}
    </Text>
    {links.map((link, index) => (
      <Link key={index} href={link.href} style={{ textDecoration: 'none' }} _hover={{ textDecoration: 'underline' }}>
        {link.text}
      </Link>
    ))}
  </Stack>
);

const Footer = () => {
  const { t } = useTranslation("common");
  const bgColor = useColorModeValue(
    "linear(to-l, brand.800, brand.700)",
    "linear(to-l, brand.900, brand.800)"
  );
  const textColor = useColorModeValue("gray.100", "gray.200");

  const linkSections: LinkSection[] = [
    {
      header: t("footerLinksHeader tellimine"),
      links: [
        { href: "/teenusetingimused/", text: t("button teenuse tingimused") },
        { href: "/privaatsuspoliitika/", text: t("button privaatsuspoliitika") }
      ]
    },
    {
      header: t("footerLinksHeader ettevõte"),
      links: [
        { href: "/meist/", text: t("button meist") },
        { href: "/kontakt/", text: t("button kontakt") }
      ]
    },
    {
      header: t("footerLinksHeader teenused"),
      links: [
        { href: "/kodulehe-turundus/", text: t("button turundus") },
        { href: "/veebiarendus/", text: t("button kodulehe tegemine") },
        { href: "/kodulehe-sisuloome/", text: t("button sisu loomine") },
        { href: "/kodulehe-hooldus/", text: t("button kodulehe hooldus") }
      ]
    }
  ];

  return (
    <Box
      bgGradient={bgColor}
      color={textColor}
      borderTop='1px solid'
      borderColor={textColor}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10} textAlign='left'>
          <VStack align='flex-start'>
            <Text
              fontSize={"2xl"}
              fontWeight={"bold"}
              letterSpacing={"-.1rem"}
              mb={4}
            >
              Kodulehe Tellimine OÜ
            </Text>
            <Text fontSize={"sm"}>{t("footer aadress")}</Text>
            <Text fontSize={"sm"}>Tel: +372 56 8844 56</Text>
          </VStack>
          {linkSections.map((section: LinkSection, index: number) => (
            <FooterLinkSection key={index} {...section} />
          ))}
        </SimpleGrid>
        <HStack justifyContent='center' spacing={4} mt={8}>
          {socialLinks.map((link: SocialLink, index: number) => (
            <ChakraLink
              key={index}
              href={link.href}
              isExternal
              title={link.title}
            >
              <Icon as={link.icon} boxSize={5} color={textColor} />
            </ChakraLink>
          ))}
        </HStack>
      </Container>
      <Text py={6} textAlign="center" fontSize="sm">
        © 2020-2024 {t("footer õigused kaitstud")}
      </Text>
    </Box>
  );
};

export default Footer;