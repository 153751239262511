import React from "react";
import {
  Button,
  ButtonProps,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsSun, BsMoonStarsFill } from "react-icons/bs";

export default function DarkMode(props: ButtonProps) {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Button
      aria-label='Toggle Color Mode'
      onClick={toggleColorMode}
      _focus={{ boxShadow: "none" }}
      w='fit-content'
      bg={useColorModeValue("brand.500", "brand.600")}
      color={useColorModeValue("brand.100", "brand.200")}
      _hover={{
        bg: useColorModeValue("brand.600", "brand.500"),
      }}
      {...props}
    >
      {colorMode === "light" ? <BsMoonStarsFill /> : <BsSun />}
    </Button>
  );
}
