import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Button, Popover, PopoverContent, PopoverTrigger, Stack, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";

type LanguageKey = 'et' | 'en' | 'de' | 'fi' | 'lv';

type LanguageInfo = {
  countryCode: string;
  ariaLabel: string;
};

type Languages = {
  [K in LanguageKey]: LanguageInfo;
};

type LanguageSwitcherProps = {
  onToggle: () => void;
};

const FLAG_SIZE = "1.3em";
const LANGUAGES: Languages = {
  et: { countryCode: "EE", ariaLabel: "Eesti keel" },
  en: { countryCode: "GB", ariaLabel: "English language" },
  de: { countryCode: "DE", ariaLabel: "Deutsche Sprache" },
  fi: { countryCode: "FI", ariaLabel: "Suomen kieli" },
  lv: { countryCode: "LV", ariaLabel: "Latviešu valoda" },
};

const Flag = ({ countryCode, ariaLabel }: LanguageInfo) => (
  <ReactCountryFlag
    countryCode={countryCode}
    svg
    aria-label={ariaLabel}
    style={{
      width: FLAG_SIZE,
      height: FLAG_SIZE,
    }}
  />
);

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ onToggle }) => {
  const { changeLanguage, languages, language } = useI18next() as {
    changeLanguage: (lang: LanguageKey) => void;
    languages: LanguageKey[];
    language: LanguageKey;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLanguageChange = (lang: LanguageKey) => {
    changeLanguage(lang);
    onClose();
    onToggle();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} trigger='hover' placement='bottom-start'>
      <PopoverTrigger>
        <Button
          p={0}
          bg={useColorModeValue("brand.500", "brand.600")}
          _hover={{
            bg: useColorModeValue("brand.600", "brand.500"),
          }}
        >
          <Flag {...LANGUAGES[language]} />
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex={4}>
        {languages.map(
          (mappedLanguage) =>
            mappedLanguage !== language && (
              <Stack
                w={"100%"}
                key={mappedLanguage}
                p={4}
                spacing={2}
                bg={useColorModeValue("brand.500", "brand.800")}
              >
                <Button
                  onClick={() => handleLanguageChange(mappedLanguage)}
                  bg={useColorModeValue("brand.400", "brand.700")}
                  _hover={{
                    bg: useColorModeValue("brand.300", "brand.600"),
                  }}
                >
                  <Flag {...LANGUAGES[mappedLanguage]} />
                </Button>
              </Stack>
            )
        )}
      </PopoverContent>
    </Popover>
  );
};

export default LanguageSwitcher;