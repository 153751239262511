import React from "react";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";
import { Link as I18nextLink } from "gatsby-plugin-react-i18next";

type CustomLinkProps = {
  href: string;
  isExternal?: boolean;
} & Omit<LinkProps, "as" | "to">;

export default function CustomLink({
  href,
  isExternal,
  ...restProps
}: CustomLinkProps) {
  const linkProps = isExternal
    ? { href, to: "" }
    : { as: I18nextLink, to: href };
  return <ChakraLink {...linkProps} {...restProps} />;
}
