import React, { useState, useEffect, useCallback } from 'react';
import { FaAngleUp } from 'react-icons/fa';
import { Box, Button, Icon, useColorMode } from '@chakra-ui/react';

const ScrollToTop = ({ visibilityThreshold = 400, customIcon = FaAngleUp, ariaLabel = 'Scroll to top', bottom = 10, right = { base: 15, md: 20 } }) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { colorMode } = useColorMode();

  const handleScroll = useCallback(() => {
    if (window.scrollY > visibilityThreshold) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  }, [visibilityThreshold]);

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 100);
    window.addEventListener('scroll', throttledHandleScroll);
    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, [handleScroll]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const buttonStyles = {
    backgroundColor: colorMode === 'light' ? 'brand.500' : 'brand.100',
    color: colorMode === 'light' ? 'white' : 'brand.500',
    _hover: {
      backgroundColor: colorMode === 'light' ? 'brand.600' : 'brand.400',
      color: 'white',
      border: `2px solid ${colorMode === 'light' ? 'brand.600' : 'brand.200'}`,
    },
    _focus: {
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
    },
    transition: 'opacity 0.3s',
    opacity: showTopBtn ? 1 : 0,
  };

  return (
    <Box position='relative'>
      {showTopBtn && (
        <Button
          aria-label={ariaLabel}
          position='fixed'
          bottom={bottom}
          right={right}
          zIndex={40}
          {...buttonStyles}
          borderRadius='50%'
          height={50}
          width={50}
          cursor='pointer'
          onClick={goToTop}
        >
          <Icon as={customIcon} fontSize='2xl' />
        </Button>
      )}
    </Box>
  );
};

function throttle(func: (...args: any[]) => void, limit: number) {
  let inThrottle: boolean;
  return function (this: any) {
    const args: any[] = [...arguments];
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export default ScrollToTop;
