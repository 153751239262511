import { extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";

const theme = {
  colors: {
    brand: {
      "50": "#e6f4f9", // Kõige heledam sinine
      "100": "#b8dcef", // Heledam sinine
      "200": "#8ac4e5", // Hele sinine
      "300": "#5cabdb", // Sinine
      "400": "#3292d1", // Tumenev sinine
      "500": "#005A8D", // Põhisinine (Brändi Värv)
      "600": "#004d7a", // Sügav sinine
      "700": "#003e68", // Veel sügavam sinine
      "800": "#002f55", // Sügavaim sinine
      "900": "#001f43", // Kõige tumedam sinine
    },
    complementary: {
      // secondary
      "50": "#e0f5fd", // Kõige heledam teisene sinine
      "100": "#b3e4fb", // Heledam teisene sinine
      "200": "#80d3f9", // Hele teisene sinine
      "300": "#4dc1f7", // Teisene sinine
      "400": "#26b0f5", // Tumenev teisene sinine
      "500": "#007ACC", // Teisene sinine (Brändi Värv)
      "600": "#0066a3", // Sügav teisene sinine
      "700": "#00527b", // Veel sügavam teisene sinine
      "800": "#003d52", // Sügavaim teisene sinine
      "900": "#00292a", // Kõige tumedam teisene sinine
    },
    highlight: {
      // tertiary
      "50": "#e6faf6", // Kõige heledam türkiis
      "100": "#bdf0ea", // Heledam türkiis
      "200": "#8fe6de", // Hele türkiis
      "300": "#59dcd2", // Türkiis
      "400": "#2fd3c6", // Tumenev türkiis
      "500": "#00B2A9", // Türkiis (Brändi Värv)
      "600": "#008d86", // Sügav türkiis
      "700": "#006864", // Veel sügavam türkiis
      "800": "#004342", // Sügavaim türkiis
      "900": "#001f21", // Kõige tumedam türkiis
    },
    warmAccent: {
      // accent
      "50": "#ffebe6", // Kõige heledam soe aktsent
      "100": "#ffc7b8", // Heledam soe aktsent
      "200": "#ffa38a", // Hele soe aktsent
      "300": "#ff7f5c", // Soe aktsent
      "400": "#ff5b2e", // Tumenev soe aktsent
      "500": "#FF7043", // Soe aktsent (Brändi Värv)
      "600": "#cc5936", // Sügav soe aktsent
      "700": "#994329", // Veel sügavam soe aktsent
      "800": "#662d1c", // Sügavaim soe aktsent
      "900": "#33170e", // Kõige tumedam soe aktsent
    },
    freshAccent: {
      "50": "#f0f9e6", //(Kõige heledam roheline/oranž)
      "100": "#dcefbc", //(Heledam roheline/oranž)
      "200": "#c4e58a", //(Hele roheline/oranž)
      "300": "#abdb59", //(Roheline/oranž)
      "400": "#92d132", //(Tumenev roheline/oranž)
      "500": "#7ACC00", //(Roheline/oranž - Brändi Värv)
      "600": "#66a300", //(Sügav roheline/oranž)
      "700": "#527b00", //(Veel sügavam roheline/oranž)
      "800": "#3d5200", //(Sügavaim roheline/oranž)
      "900": "#292a00", //(Kõige tumedam roheline/oranž)
    },
    coolAccent: {
      "50": "#e6f0f9", //(Kõige heledam tume roheline/oranž)
      "100": "#bcdcef", //(Heledam tume roheline/oranž)
      "200": "#8ab8e5", //(Hele tume roheline/oranž)
      "300": "#59a0db", //(Tume roheline/oranž)
      "400": "#3288d1", //(Tumenev tume roheline/oranž)
      "500": "#0066CC", //(Tume roheline/oranž - Brändi Värv)
      "600": "#0054a3", //(Sügav tume roheline/oranž)
      "700": "#00427b", //(Veel sügavam tume roheline/oranž)
      "800": "#002f55", //(Sügavaim tume roheline/oranž)
      "900": "#001f33", //(Kõige tumedam tume roheline/oranž)
    },
  },
  fonts: {
    brandHeading: "Montserrat, sans-serif",
    brandBody: "Open Sans, sans-serif",
    cta: "Roboto Condensed, sans-serif",
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      "html, body, main": {
        bg: props.colorMode === "dark" ? "brand.900" : "brand.100",
        color: props.colorMode === "dark" ? "brand.100" : "brand.900",
        fontFamily: "brandBody",
      },
      "h1, h2, h3, h4, h5, h6": {
        fontFamily: "brandHeading",
      },
      "p, a, li, span": {
        fontFamily: "brandBody",
      },
      "button": {
        fontFamily: "cta",
      },
      a: {
        textDecoration: "none",
      },



    }),
  },
};

export default extendTheme(theme);
