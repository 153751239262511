import React, { ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../theme/theme";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";

interface LayoutProps {
  children?: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ChakraProvider theme={theme}>
      <Navbar />
      <main>
        {children}
      </main>
      <ScrollToTop />
      <Footer />
    </ChakraProvider>
  );
};

export default Layout;
