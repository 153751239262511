exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kodulehe-hooldus-tsx": () => import("./../../../src/pages/kodulehe-hooldus.tsx" /* webpackChunkName: "component---src-pages-kodulehe-hooldus-tsx" */),
  "component---src-pages-kodulehe-loomine-tsx": () => import("./../../../src/pages/kodulehe-loomine.tsx" /* webpackChunkName: "component---src-pages-kodulehe-loomine-tsx" */),
  "component---src-pages-kodulehe-sisuloome-tsx": () => import("./../../../src/pages/kodulehe-sisuloome.tsx" /* webpackChunkName: "component---src-pages-kodulehe-sisuloome-tsx" */),
  "component---src-pages-kodulehe-turundus-tsx": () => import("./../../../src/pages/kodulehe-turundus.tsx" /* webpackChunkName: "component---src-pages-kodulehe-turundus-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-meist-tsx": () => import("./../../../src/pages/meist.tsx" /* webpackChunkName: "component---src-pages-meist-tsx" */),
  "component---src-pages-privaatsuspoliitika-tsx": () => import("./../../../src/pages/privaatsuspoliitika.tsx" /* webpackChunkName: "component---src-pages-privaatsuspoliitika-tsx" */),
  "component---src-pages-teenusetingimused-tsx": () => import("./../../../src/pages/teenusetingimused.tsx" /* webpackChunkName: "component---src-pages-teenusetingimused-tsx" */),
  "component---src-pages-veebiarendus-tsx": () => import("./../../../src/pages/veebiarendus.tsx" /* webpackChunkName: "component---src-pages-veebiarendus-tsx" */)
}

